.header {
    background-image: url("../../assets/head-01.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: darken;
    /* background-color: rgb(0, 0, 0); */
    padding-top: 400px;
    height: 1024px;
}

.text-center {
    text-align: center;
}

.logo {
    height: 60px;
    /* margin-top: 3%; */
    /* margin-left: 3%; */
}

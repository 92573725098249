.footer {
    background-color: rgb(0, 0, 0);
    height: 350px;
    position: relative;
    bottom: 0px;
    margin-top: 150px;
}

@media (max-width: 1200px) {
    .footer {
        background-color: rgb(0, 0, 0);
        height: 500px;
        position: relative;
        bottom: 0px;
        margin-top: 200px;
    }
}

.text-center {
    text-align: center;
}

.box {
    padding-top: 120px;
    position: relative;
}

.social-logo {
    height: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
    /* width: 40px; */
}

.bg-black {
    background-color: rgba(0, 0, 0, 0.632);
}
.top-nav {
    position: fixed;
    width: 100%;
    z-index: 1;
    box-shadow: 0 32px 64px 0 rgba(0, 0, 0, 0.854);
}
.logo {
    height: 20px;
    margin-right: 10px;
}
.dropdown .dropdown-btn {
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit; /* Important for vertical align on mobile phones */
    margin: 0; /* Important for vertical align on mobile phones */
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    float: none;
    color: black;
    text-decoration: none;
    display: block;
    text-align: left;
}

.dropdown button:hover {
    color: #ddd;
}

.dropdown a:hover {
    color: #ddd;
}

.dropdown Link:hover {
    color: #ddd;
}

@media only screen and (min-width: 768px) {
    .dropdown:hover .dropdown-content {
        display: block;
    }
}

text-white {
    background-color: white;
}

html {
    scroll-behavior: smooth;
}

@font-face {
    font-family: "HussainiNastaleeq";
    src: local("HussainiNastaleeq"),
        url(../../assets/fonts/HussainiNastaleeqRegular.ttf) format("truetype");
}

@font-face {
    font-family: "lotus";
    src: local("lotus"),
        url(../../assets/fonts/lotuslinotype-light.otf) format("opentype");
}

.Urdu {
    font-family: HussainiNastaleeq;
    font-size: 20px;
}

.Arabic {
    font-family: lotus;
    font-size: 20px;
}

.Persian {
    font-family: HussainiNastaleeq;
    font-size: 25px;
}

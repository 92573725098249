.link-color-black {
    color: black;
}

.card-text {
    font-size: 20px;
    line-height: 160%;
}

.card {
    z-index: 0;
    background-color: rgb(255, 255, 255);
    border: none;
    transition: 0.3s;
}

.card:hover {
    box-shadow: 0 16px 31px 0 rgba(0, 0, 0, 0.2);
}

.card-alt {
    z-index: 0;
    background-color: rgba(244, 253, 255, 0.778);
    transition: 0.3s;
    border: none;
}

.card-alt:hover {
    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.2);
}

.image-left {
    float: left;
}

.text-blue {
    color: rgb(31, 101, 229);
}

.large-font {
    font-size: 34px;
}

.color-coral {
    color: coral;
}

.p-5 {
    padding: 5px;
}

.p-10 {
    padding: 10px;
}

.p-20 {
    padding: 20px;
}
